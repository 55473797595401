// Custom Footer (And structure)
.navbar-bottom-custom{
background:#e40613;           
height:300px;
color:#FFF;
font-size:16px;
margin-bottom:0;
padding-bottom:0;
border-radius:0px;
}

.navbar-bottom-custom > .logo-row{
padding-top:50px;
padding-bottom:20px;
}
                           


                            .nabvar-bottom-custom > .info-row{
                              padding-top:20px;
                              padding-bottom:20px;
                            }

                            * {
                    margin: 0;
                    padding: 0;
                  } 

                  html, body {
                    height: 100%;
                  }

                  #wrap {
                    min-height: 100%;
                  }

                  #main {
                    overflow: auto;
                    padding-bottom: 300px; /* must be same height as the footer */
                  }

                  #footer {
                    position: relative;
                    margin-top: -300px; /* negative value of footer height */
                    height: 300px;
                    clear: both;
                    overflow: hidden;
                  } 






// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//Font-Awesome
@import "node_modules/font-awesome/scss/font-awesome";


//Basic
body {
  margin-top:45px !important;
  background-color:#FFF !important;
}

//Colors
.red{
  color:#e00120;
}

//Buffers
.top-buffer{
  margin-top:10px;
}
label.top-buffer{
  margin-top:7px;
}
.bottom-buffer{
  margin-bottom:20px;
}

//Colors
.amb-background{
  background-color:#e00120 !important;
}

#login-logo{
  width:225px;
  height:auto;
}


.hide{
  display:none;
}


 


//FORMS & BUTTONS
    input,select{
      border-radius:0 !important;
      background:#e0e0e0 !important;
      height:45px !important;
      border:none !important;
    }
    button{
      border-radius:0 !important;
      font-weight:bold !important;
      color:#FFF !important;
      text-align:center !important;
      padding:10px 50px 10px 50px !important;
      min-width:200px !important;
    }
    .btn-amb-red{
      background:#e40613;

      text-align:center;
    }
    .btn-amb-gray{
      background:#797979;
      color:#FFF;

    }
    .amb-href{
      color:#e40613;
      font-weight:bold;
    }
    hr{
      margin-top:20px;
      margin-bottom:20px;
    }

@font-face {
  font-family: Glegoo;
  src: url('/public/fonts/glegoo/Glegoo-Regular.ttf');
}


h4 {
  font-family: Glegoo;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 30px;
}
h2 {
  font-family: Glegoo;
  font-weight: bold;
  color: black;
  margin-bottom: 40px;
  font-size: 32px;
}

#header-logo {
  //width: 180px;
}

.navbar-brand {
  padding: 3px;
  margin-right: 20px;
}

.successful-message {
  background: #27ae60;
  color: white;
  padding: 10px 20px;
  margin-bottom: 20px;
  margin-top: 30px;
  font-size: 18px;
}

h4+hr {
  display: none;
}
h2 > img {
  width: 40px;
  margin-right: 20px;
  vertical-align: middle;
}
.amb-logo {
  //margin-top: 70px;
  //margin-bottom: 50px;
}
.amb-logo > img {
  width: 270px;
}

.nav.navbar-nav li > div {
  display: none;
  position: absolute;
  background: white;
  height: 1px;
  left: 0;
  top: 100%;
  width: 100%;
}
.nav.navbar-nav li.active > div {
  display: block;
}

.nav.navbar-nav li {
  padding: 10px 20px;
  position: relative;
}
.nav.navbar-nav li.active a {
  background: white;
  color: #e11020;
}
.nav.navbar-nav li.active {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}
.nav.navbar-nav li a:hover,
.nav.navbar-nav li.active a:hover {
  background: white;
  color: #e11020;
}
.nav.navbar-nav.menu-items li {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.table.table-striped th {
  font-size: 18px;
}
[name="building"] {
  padding: 0px 10px;
  height: 50px !important;
  font-weight: bold;
  font-size: 15px;
  max-width: 100%;
  //-webkit-appearance: none;
  //background-image: url('public/images/icons/select_arrow.png') !important;
}
.nav > li > a > img {
  width: 30px;
  margin-right: 10px;
}

.site-title {
  padding-top: 20px;
  padding-bottom: 20px;
  display: inline-block;
  vertical-align: bottom;
  float: right;
}

.site-title > span {
  //margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
  color: black;
}

.submit-button {
  border-radius:0 !important;
  font-weight:bold !important;
  color:#FFF !important;
  text-align:center !important;
  width: 200px;
  padding: 12px 15px !important;
  margin-bottom: 5px;
  height: auto !important;
  text-transform: uppercase;
  background-color: #797979 !important;
  font-size: 17px;
  background-color: #797979;
  font-size: 17px;
}
.submit-button:hover {
  border: 1px solid #797979 !important;
  background: white !important;
  color: #797979 !important;
}

.btn-default {
  color: #636b6f !important;
  border-color: #ccc !important;
}


@media screen and (max-width: 992px) {
  .user-email {
	display: none;
  }
}

@media screen and (max-width: 730px) {
  .user-email {
	display: inline-block;
  }
  .site-title {
	float: left;
	display: block;
  }
}


/* Data table */

table.dataTable.no-footer {
  border-bottom: 0px;
}
table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 2px solid #ddd;
}
table.dataTable thead .sorting_asc {
  background-image: url('../images/icons/sort_asc.png');
}
table.dataTable thead .sorting_desc {
  background-image: url('../images/icons/sort_desc.png');
}


[name="building"],
select.form-control {
  background-image: url('../images/icons/select_arrow.png') !important;
  background-repeat: no-repeat !important;
  background-position: center right 13px !important;
  -webkit-appearance: none;
  padding-right: 40px;
  padding-left: 15px;
}

.form-control {
  box-shadow: none;
}

.no-amb-services {
  background-color: rgba(0,0,0,0.1);
  padding: 15px;
}

.no-amb-services input {
  display: inline-block;
  margin-top: 20px;
  width: 340px;
}

@media screen and (max-width: 500px) {
	.no-amb-services input {
	  font-size: 13px;
	  width: 100%;
	}
}

.navbar-default .navbar-toggle {
  border: 0px;
}
.navbar-toggle {
  min-width: initial !important;
  padding: 9px 10px !important;
  
}